@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* Variable Section S */
:root {
  --headingfont: "Montserrat", sans-serif;
  --basefont: "Poppins", sans-serif;
  --robtslbfont: "Roboto Slab", serif;

  --body_color: #00112c;
  --headingcolor: #00112c;
  --footer_color: #333333;

  --black-md-color: #00112c;
  --orangecolor: #ff5b2e;
  --gray-lg-color: #f8f9fa;
  --white: #ffffff;
  --black: #000000;

  --anchor: #1a2027;
  --anchorhover: #ff5b2e;

  --btn1bg: #ff3f2a;
  --btn1text: #ffffff;
  --btn1border: #ffffff;
  --btn1bghover: #ffffff;
  --btn1texthover: #ff3f2a;
  --btn1texthover: #ff3f2a;

  --btn2bg: #ffffff;
  --btn2text: #1a2027;
  --btn2border: #1a2027;
  --btn2bghover: #1a2027;
  --btn2borderhover: #1a2027;
  --btn2texthover: #ffffff;
}
/* Variable Section E */

/* Typography Section S */

body {
  font-family: var(--basefont);
  font-weight: 400;
  color: var(--body_color);
  font-size: 16px;
  line-height: 28px;
}

h2:empty,
h3:empty,
h4:empty,
h5:empty,
h6:empty,
p:empty {
  display: none;
}

.list-style ul,
ul.list-style {
  list-style: none;
  margin: 0 0;
  padding: 0 0;
}

strong {
  font-weight: 700;
}
a {
  transition: 400ms;
  text-decoration: none;
  color: var(--anchor);
}
a:hover,
a:focus {
  color: var(--anchorhover);
}
p {
  margin: 0 0 15px 0;
}
p + p {
  margin: 15px 0 0 0;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-uppercase {
  text-transform: uppercase;
}

.white-text,
.white-text h1,
.white-text h2,
.white-text h3,
.white-text h4,
.white-text h5,
.white-text h6 {
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  font-weight: 400;
  margin-bottom: 15px;
}

h1 {
  font-family: var(--headingfont);
  font-size: 54px;
  color: var(--headingcolor);
  line-height: 68px;
  font-weight: 600;
}
h2 {
  font-family: var(--headingfont);
  font-size: 48px;
  color: var(--headingcolor);
  line-height: 60px;
  font-weight: 600;
}
h3 {
  font-family: var(--headingfont);
  font-size: 34px;
  color: var(--headingcolor);
  line-height: 48px;
  font-weight: 600;
}
h4 {
  font-family: var(--headingfont);
  font-size: 28px;
  color: var(--headingcolor);
  line-height: 36px;
  font-weight: 600;
}
h5 {
  font-family: var(--headingfont);
  font-size: 24px;
  color: var(--headingcolor);
  line-height: 24px;
  font-weight: 400;
}
h6 {
  font-family: var(--headingfont);
  font-size: 18px;
  color: var(--headingcolor);
  line-height: 24px;
  font-weight: 600;
}

@media (max-width: 1629px) {
}
@media (max-width: 1429px) {
  body {
    font-size: 17px;
    line-height: 30px;
  }
}
@media (max-width: 1399px) {
  h1 {
    font-size: 80px;
    line-height: 100px;
  }
  h3 {
    font-size: 35px;
    line-height: 50px;
  }
  h2 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1199px) {
  h1 {
    font-size: 63px;
    line-height: 90px;
  }
  h2 {
    font-size: 34px;
    line-height: 40px;
  }
  h3 {
    font-size: 30px;
    line-height: 40px;
  }
  h4 {
    font-size: 27px;
    line-height: 32px;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 16px;
    line-height: 27px;
  }
  h1 {
    font-size: 47px;
    line-height: 60px;
  }
  h2 {
    font-size: 30px;
    line-height: 48px;
  }
  h3 {
    font-size: 30px;
    line-height: 46px;
  }
  h4 {
    font-size: 24px;
    line-height: 30px;
  }
}
/* Typography Section E */

/* Button Style Section S */
.btn-group > div {
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
}
.btn-group > div:first-child {
  margin-left: 0;
  margin-bottom: 15px;
}
.btn-group > div:last-child {
  margin-right: 0;
  margin-bottom: 15px;
}

.button a,
.button button {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  min-width: 190px;
  padding: 15px 20px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid;
  display: inline-block;
  text-decoration: none;
}

.button-1 a,
.button-1 button {
  background-color: var(--btn1bg);
  border-color: var(--btn1bg);
  color: var(--btn1text);
}
.button-1 a:hover,
.button-1 button:hover {
  background-color: var(--btn1bghover);
  border-color: var(--btn1texthover);
  color: var(--btn1texthover);
}

.button-2 a,
.button-2 button {
  background-color: transparent;
  border: solid 1px var(--btn2border);
  color: var(--btn2text);
}
.button-2 a:hover,
.button-2 button:hover {
  background-color: var(--btn2bghover);
  border-color: var(--btn2borderhover);
  color: var(--btn2texthover);
}

@media (max-width: 991px) {
  .btn-group > div {
    display: block;
    margin: 0 0 8px;
  }
}
@media (max-width: 767px) {
  [class*="button-"] a {
    font-size: 14px;
    padding: 12px 30px;
  }
}
/* Button Style Section E */

/* Form Section S */
input {
  font-family: var(--basefont);
}

::-webkit-input-placeholder {
  opacity: 1;
}
::-moz-placeholder {
  opacity: 1;
}
:-ms-input-placeholder {
  opacity: 1;
}
:-moz-placeholder {
  opacity: 1;
}

textarea,
select,
input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]) {
  font-family: var(--basefont);
  border-radius: 0px;
  color: var(--body_color);
  border-radius: 10px;
  background-color: #fff;
  border: none;
  padding: 20px;
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}
input[type="submit"] {
  background-color: #fff;
  color: var(--body_color);
  border: none;
  border-radius: 10px;
  transition: 400ms;
  display: inline-block;
  font-size: 16px;
  font-family: var(--basefont);
  font-weight: 500;
  min-width: 180px;
  padding: 18px 35px;
  text-align: center;
  cursor: pointer;
}
input[type="submit"]::-moz-focus-inner {
  border: 0;
}
input[type="submit"]:hover {
  background-color: var(--btn1bghover);
  color: var(--btn1texthover);
}
textarea {
  resize: none;
}
::-webkit-input-placeholder,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
}
::-moz-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
}
:-ms-input-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
}
:-moz-placeholder,
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
}
/* Form Section E */

/* Slick Slider S */
.slick-slider .slick-arrow {
  background: transparent;
  border: none;
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 50px;
  height: 50px;
  font-size: 0px;
  color: #fff;
  cursor: pointer;
}
.slick-slider .slick-arrow.slick-next {
  left: auto;
  right: 0;
}
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 42px;
  line-height: 1;
  opacity: 0.75;
  color: #525252;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-slider .slick-arrow.slick-prev {
  left: -5%;
}
.slick-slider .slick-arrow.slick-next {
  right: -5%;
}

.slick-slider .slick-dots {
  text-align: center;
  padding: 0;
  margin: 0;
}
.slick-slider .slick-dots li {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
}
.slick-slider .slick-dots li button {
  border-radius: 100%;
  background-color: #ff2d16;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
  text-indent: -9999px;
  cursor: pointer;
  opacity: 0.5;
  tansition: 0.5s;
  margin: auto;
}
.slick-slider .slick-dots li.slick-active button {
  opacity: 1;
}
.slick-dots li button:before {
  width: 100%;
  height: 100%;
}
@media (max-width: 1199px) {
  .slick-slider .slick-arrow.slick-next {
    right: 0;
    left: auto;
  }
  .slick-slider .slick-arrow.slick-prev {
    left: 0;
  }
}
@media (max-width: 767px) {
  .slick-slider .slick-dots li {
    width: 8px;
    height: 8px;
  }
}

/* Slick Slider E */

/* Header and Footer S */
header.header {
  position: fixed;
  width: 100%;
  z-index: 99;
  transition: 200ms;
  background-color: transparent;
}
header.header.headerfixed {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}
header.header .head-inner {
  padding: 0 75px;
}
header.header .head-inner .logo {
  width: 235px;
}
header.header .head-inner .main-navbar {
  width: calc(100% - 235px);
}
header.header .head-inner .logo a,
header.header .head-inner .logo a img {
  display: block;
}
header.header .main-navbar .main-nav {
  width: calc(100% - 225px);
  text-align: right;
  padding-right: 40px;
}
header.header .main-nav ul li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 33px;
}
header.header .main-nav ul li a {
  display: inline-block;
  font-weight: 600;
  color: var(--light-color);
  padding: 45px 0 35px;
  font-size: 18px;
  line-height: 23px;
  position: relative;
}
header.header .main-nav ul li a:hover {
  color: var(--light-hover-color);
}
header.header .main-nav ul li a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: #3719ca;
  transition: 200ms;
}
header.header .main-nav ul li:hover a:after {
  width: 100%;
}
header.header .main-navbar .action-btn {
  width: 225px;
}
header.header .main-navbar .action-btn.button-1 a {
  padding: 15px 25px 15px 45px;
}
header.header .main-navbar .action-btn.button-1 a:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 12px;
  background: url(./assets/images/arrow-white.svg) no-repeat center / contain;
  top: 50%;
  left: 23px;
  transform: translateY(-50%);
}
header.header .main-navbar .action-btn.button-1 a:hover:before {
  filter: contrast(0.5);
}

footer.footer a:hover {
  color: var(--light-color);
}
footer.footer {
  font-size: 16px;
  line-height: 22px;
  padding: 150px 0 0;
  font-family: "R-Flex";
  font-weight: 300;
}
footer.footer .logo-content {
  padding: 0 0 115px;
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(
    110.5deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 42.69%,
    rgba(255, 255, 255, 0) 233.14%
  );
}
footer.footer .logo-content .logo {
  width: 275px;
}
footer.footer .logo-content .logo img {
  display: block;
}
footer.footer .logo-content .content {
  width: calc(100% - 275px);
  max-width: 730px;
  margin: 0 100px 0 auto;
  font-size: 24px;
  line-height: 36px;
  font-family: var(--basefont);
}
footer.footer .footer-nav {
  padding: 50px 0 60px;
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(
    110.5deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 42.69%,
    rgba(255, 255, 255, 0) 233.14%
  );
}
footer.footer .footer-nav .nav-items-wrap {
  margin: 0 -45px;
}
footer.footer .nav-items {
  width: calc(20% - 90px);
  margin: 0 45px;
}
footer.footer .nav-title {
  text-transform: capitalize;
  color: var(--light-color);
  text-decoration: underline;
  padding: 0 0 35px;
}
footer.footer .nav-items .link-box ul li + li {
  margin: 15px 0 0;
}
/* Header and Footer E */

/* Global Section S */
* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}

img {
  height: auto;
  max-width: 100%;
}
img.full {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
img.block {
  display: block;
}
.lazy[data-bg] {
  background-repeat: no-repeat;
  background-size: cover;
}
/* Global Section E */

/* Responsive Section S */
@media (min-width: 768px) {
}

@media (max-width: 1629px) {
}

@media (max-width: 1399px) {
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (max-width: 500px) {
}
/* Responsive Section E */

/*product listing css*/

.wbr-product-listing-filters .accordion-body {
  padding: 1rem 1.25rem;
  max-height: 20vh;
  overflow-y: auto;
}
.accordion-body::-webkit-scrollbar {
  width: 5px;
}
.accordion-body::-webkit-scrollbar-thumb {
  background: #888;
}
.card-short {
  border-bottom: 1px solid #ccc;
}
.acr-btn {
  font-size: 18px;
  font-weight: 600;
  color: #000 !important;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.filter {
  display: none;
}

.desktop-mobile-view {
  display: none;
}

@media (max-width: 767px) {
  .filter {
    display: block;
    text-align: center;
    padding: 10px;
    background: #333333;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
  #filter1 {
    margin-left: 2rem;
  }
  #filtercolumn {
    display: none;
    background: white;
    position: fixed;
    z-index: 9;
    top: 0;
    padding: 15px;
    bottom: 0;
    overflow: auto;
  }

  .desktop-mobile-view {
    display: flex;
  }
  .desktop-mobile-view .modal-title {
    font-weight: 500;
    font-size: 22px;
  }
  #ShortColumn {
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 999;
    background: #fff;
    padding: 25px !important;
    left: 0;
    right: 0;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #fdfdfd;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

h2 {
  font-size: 30px;
  line-height: 34px;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
  border: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
  border: 0;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  font-size: 14px;
}

.acr-btn {
  font-size: 15px;
  font-weight: 600;
  color: #000 !important;
}
.about-us {
  padding: 2rem 0;
}
.about-us p {
  font-size: 15px;
}
.contact-us {
  padding: 2rem 0;
}
.contact-us p {
  font-size: 15px;
}
.contact-para {
  line-height: 28px;
}
.contact-para span {
  display: block;
}
.contact-para span a {
  color: #027eb5;
}
.contact-us .map_wrap .main-map {
  height: 400px;
}
.contact-us .map_wrap .main-map iframe {
  width: 100%;
  height: 100%;
}
